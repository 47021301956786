import {
   Box,
   Button,
   Flex,
   Heading,
   Table,
   Tbody,
   Th,
   Thead,
   Tr,
   Td,
   Checkbox,
   Input,
   useColorModeValue,
} from "@chakra-ui/react";
import { isPaid, opIsFree } from "store/op";
import Balance from "./balance";
import Currency from "./currency";
import Lang from "./lang";
import Label from "./label";
import * as operations from "./ops";
import { useMemo } from "react";
import { useStore, useS } from "store";
import { camelCase } from "lodash";
import { OpMenu } from "./admin";
import { formatNumber } from "shed";
import { ColorModeSwitcher } from "ColorModeSwitcher";

const Playground = () => {
   const paidOps = useStore(state =>
      Object.keys(state.ops).filter(key => state.ops[key].price > 0)
   );

   const ops = useStore(state =>
      Object.entries(state.ops).map(([key, value]) => ({
         key,
         ...value,
      }))
   );

   const modeStyle = useColorModeValue({
      // light
      userPanel: { bg: "blue.50" },
      adminPart: { bg: "purple.50" }
   }, {
      // dark
      userPanel: { bg: "blue.900" },
      adminPart: { bg: "purple.900" }
   });

   const style = {
      part: { borderRadius: "12px", p: "8px 16px" },
      userPart: { pl: 0 },
      ...modeStyle,
   };

   // console.log(formatNumber(59.003, 3, 2));

   const [paid, free] = useMemo(() => {
      return Object.keys(operations).reduce(
         ([paid, free], key) => {
            const Component = operations[key],
               c = <Component {...{ key }} />;
            const op = ops.find(op=>op.key===camelCase(key));
            return op && opIsFree(op)
            ? [paid, [...free, c]]
            : [[...paid, c], free];
         },
         [[], []]
      );
   }, [ops]);

   return (
      <>
         <Flex justifyContent="space-between" alignItems="center" mb="20px" p="10px 20px">
            <Heading size="2xl">
               <Label ui>Playground</Label>
            </Heading>
            <Flex gap="20px">
               <ColorModeSwitcher size="sm" mr="20px" />
               <Lang />
            </Flex>
         </Flex>

         <Box p="0 20px">
            
            <Flex gap="30px">
               <Box {...{...style.part, ...style.userPart}}>
                  <Heading mb="1em">
                     <Label>User part</Label>
                  </Heading>

                  <Flex
                     {...style.userPanel}
                     borderRadius={6}
                     alignItems="center"
                     gap="10px"
                     p="4px 20px"
                     justifyContent="flex-end"
                  >
                     <Currency />
                     <Balance />
                  </Flex>

                  <Flex gap="20px" mt="1em">
                     <Box w="260px">
                        <Heading size="lg">
                           <Label>Paid</Label>
                        </Heading>
                        {paid}
                     </Box>
                     <Box w="260px">
                        <Heading size="lg">
                           <Label>Free</Label>
                        </Heading>
                        {free}
                     </Box>
                  </Flex>
               </Box>

               <Box {...{...style.part, ...style.adminPart}}>
                  <Heading mb="1em">
                     <Label>Admin part</Label>
                  </Heading>
                  <OpMenu />
               </Box>
            </Flex>
         </Box>
      </>
   );
};

export default Playground;
