
import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";
import "@fontsource/jetbrains-mono/400.css";
import "@fontsource/jetbrains-mono/700.css";
// import "@fontsource/inter/800.css";
// import "@fontsource/inter/900.css";

import { useMemo } from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider } from "@chakra-ui/react";
import Boot from "boot";
import Router from "Router";
import theme from "theme";

console.log(theme);

function App() {
   const qc = useMemo(
      () =>
         new QueryClient({
            defaultOptions: { queries: { refetchOnWindowFocus: false } },
         }),
      []
   );

   return (
      <ChakraProvider theme={theme}>
         <QueryClientProvider client={qc}>
            <Boot>
               <Router />
            </Boot>
         </QueryClientProvider>
      </ChakraProvider>
   );
}

export default App;
