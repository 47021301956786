import { useEffect } from "react";
import { useS } from "../store";

export const usePrefs = () => {

    const defaults = useS("defaults");
    const prefs = useS("prefs");
    const update = useS("actions.prefs.update");

    // console.log("prefs:", prefs);

    useEffect(() => {
        // console.log("defaults, prefs:", defaults, prefs);
        if (defaults && !prefs) {
            update(defaults);
        }
    }, [defaults, prefs, update]);

    return !!prefs;

};
