import { Table, Thead, Tbody, Tr, Th, Td, Checkbox, Input, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from "@chakra-ui/react";
import { round } from "shed";
import { useS } from "store";
import { useStore } from "store";
import { opIsFree } from "store/op";
import { CValue } from "./balance";
import Label from "./label";

const price = { min: 0.25, max: 99.90, step: 0.25, defaultValue: 0.25 };

const OpMenu = () => {
   const ops = useStore(state =>
      Object.entries(state.ops).map(([key, value]) => ({
         key,
         ...value,
      }))
   );

   const update = useS("actions.ops.updateOp");

   const handlePrice = (op, value) => {
      update(op.key, { price: round(value, 2) });
   };

   const toggleFree = op => {
      // console.log(op);
      const payload = {};
      if (!op.hasOwnProperty("price")) {
         payload.price = price.min;
         payload.free = false;
      } else {
         payload.free = !op.free;
      }
      update(op.key, payload);
   };

   return (
      <Table>
         <Thead>
            <Tr>
               <Th>
                  <Label>item</Label>
               </Th>
               <Th>
                  <Label>for free</Label>
               </Th>
               <Th>
                  <Label>price</Label>
               </Th>
            </Tr>
         </Thead>
         <Tbody>
            {ops.map((op, i) => (
               <Tr key={i}>
                  <Td>
                     <Label>{op.label}</Label>
                  </Td>
                  <Td>
                     <Checkbox isChecked={opIsFree(op)} onChange={()=>toggleFree(op)} />
                  </Td>
                  <Td>
                     <Slider {...price} w="120px" value={op.price} onChange={value=>{ handlePrice(op, value) }}>
                        <SliderTrack>
                           <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                     </Slider>
                  </Td>
                  <Td isNumeric>
                     <CValue int={2} currency="šķ">{ op.price }</CValue>
                  </Td>
               </Tr>
            ))}
         </Tbody>
      </Table>
   );
};

export { OpMenu };
