import { Flex, Box, Select } from "@chakra-ui/react";
import { useCallback } from "react";
import { useS } from "store";
import Label from "./label";

const Currency = () => {

   const currencies = useS("currencies"),
   current = useS("prefs.currency"),
   update = useS("actions.prefs.update");

   const handleChange = useCallback(({ target }) => {
      // console.log(target.value);
      update({ currency: target.value });
   }, [update]);

   return (
      <Flex alignItems="center">
         <Label ui>currency</Label>:&nbsp;
         <Select w="unset" size="sm" value={current} onChange={handleChange}>
            { currencies.map((currency, i) => (
               <option key={i}>{currency}</option>
            )) }
         </Select>
      </Flex>
   )

};

export default Currency;
