import { Box, Flex, Text } from "@chakra-ui/react";
import { formatNumber, round } from "shed";
import { useStore, useS } from "store";
import Label from "./label";

export const CValue = ({ children, currency, int=0, dec=2 }) => {

   const num = parseFloat(children);

   const rate = useStore(state => state.rates[currency||state.prefs.currency]);
   const prefCurrency = useS("prefs.currency");

   if (!children) return null;
   if (isNaN(num)) return children;

   const price = round(num * rate, 2);

   return `${formatNumber(price, int, dec)} ${currency||prefCurrency}`;
};

const Balance = () => {

   const balance = useS("user.balance");

   return (
      <Flex alignItems="center">
         <Label ui>balance</Label>:&nbsp;
         <Text as="b">
            <CValue>{balance}</CValue>
         </Text>
      </Flex>
   )
};

export default Balance;
