import produce from "immer";
import { useCallback } from "react";
import { getDeepKeyValue } from "shed";
import create from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";
import { storageKey, getStorage } from "./storage";


const user = {
   balance: 50,
};

const rates = {
   "šķ": 1,
   "ģ": 1.2,
};

const playground = {
   oaks: 0, houses: 0, bridges: 0, calls: 0, comments: 0,
}

const getSliceUpdater = (slice, set) => ({ update: props => set(state => ({ [slice]: {...state[slice], ...props } })) });

const useStore = create(
   devtools(
      subscribeWithSelector(
         persist(
            (set, get) => {
               const actions = {
                  update: props => set(state => ({ ...state, ...props })),
                  prefs: getSliceUpdater("prefs", set, get),
                  ops: {
                     ...getSliceUpdater("ops", set, get),
                     updateOp: (op, props) => set(produce(state => {
                        state.ops[op] = {...(state.ops[op]||{}), ...props }
                     })),
                  },
                  playground: {
                     increase: slice => set(produce(state => { state.playground[slice] += 1; })),
                  }
               };
               const state = {
                  user,
                  actions,
                  rates,
                  playground,
               };
               console.log(`state created:`, state);
               return state;
            },
            {
               name: storageKey,
               getStorage,
               partialize: state => {
                  // console.log("partialize fired, return prefs:", state.prefs);
                  return ({ prefs: state.prefs })
               },
               version: 1,
               onRehydrateStorage: () => () => {
                  console.log(`%cstorage rehydrated`, "color: orange");
                  useStore.setState({ _hasHydrated: true });
               }
            }
         )
      )
   )
);

const useS = (key, comp) => {
   const callback = useCallback((state) => getDeepKeyValue(state, key), [key]);
   const res = useStore(callback, comp);
   return res;
};

export { useStore, useS };
