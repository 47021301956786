import produce from "immer";
import { camelCase } from "lodash";
import { cl, objType } from "shed";
import { useS } from "store";
import { useStore } from "store";

const getOpNameAndFn = operation => {
   switch (objType(operation)) {
      case "function": { return { name: operation.name, fn: operation } }
      case "object": { const name = cl(operation); return { name, fn: operation[name] } }
      default: return { name: "unknown", fn: () => {} }
   }
};

const useAffordable = (operation) => {
   const balance = useS("user.balance"), ops = useS("ops");

   const { name } = getOpNameAndFn(operation);

   // console.log("useAffordable: operation:", cl(operation));
   // console.log("useAffordable: function name:", name);

   if (!ops.hasOwnProperty(name)) return true;
   if (ops[name].free) return true;
   if (!ops[name].hasOwnProperty("price")) return true;
   return balance >= ops[name].price;
};

const balance = (data, op) => {
   // console.log(`balance data:`, data);
   useStore.setState(produce(state => { state.user.balance -= data.price }));
   return op;
};

const op = operation => {

   const { name, fn } = getOpNameAndFn(operation);

   // console.log(`operation called:`, name);

   const ops = useStore.getState().ops;

   // console.log(ops);

   if (ops.hasOwnProperty(name) && ops[name].price && !ops[name].free) return balance(ops[name], fn);

   return fn;
};

const isPaid = operation => {
   const ops = useStore.getState().ops;
   const name = objType(operation) === "function"
   ? camelCase(operation.name)
   : objType(operation) === "string"
   ? camelCase(operation)
   : false;
   return name && (ops.hasOwnProperty(name) && ops[name].price > 0);
};

const
opIsFree = op => !op.hasOwnProperty("price") || op.free || op.price === 0,
opIsPaid = op => op.price > 0 && !op.free;

export { balance, op, useAffordable, isPaid, opIsFree, opIsPaid, getOpNameAndFn }
