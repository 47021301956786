import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Panel from "./panel";

const Layout = () => {

   const styles = useMultiStyleConfig("Layout");

   return (<>
      <Box __css={styles.layout}>
         <Panel content>
            <Outlet />
         </Panel>
      </Box>
      <Panel sidebar>
         sidebar
      </Panel>
   </>)

};

export default Layout;
