import { extendTheme } from "@chakra-ui/react";
// import colors from "./colors";
import styles from "./styles";
import fonts from "./fonts";
import components from "../components";

const config = {
   useSystemColorMode: true,
};

const overrides = {
   config,
   // colors,
   styles,
   components,
   fonts,
};

export default extendTheme(overrides);
