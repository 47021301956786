
import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import { cl } from "shed";

const Panel = ({ children, ...props }) => {

    const variant = cl(props);

    const styles = useMultiStyleConfig("Layout", { variant })

    return (
        <Box __css={styles.panel} name={`Panel ${cl(props)}`}>
            { children }
        </Box>
    )
};

export default Panel;
