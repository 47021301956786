import { Box, Button, Text } from "@chakra-ui/react";
import produce from "immer";
import { useState } from "react";
import { useStore } from "store";
import { useS } from "store";
import { getOpNameAndFn, op, useAffordable } from "store/op";
import { CValue } from "./balance";
import Label from "./label";

const PriceTag = ({ affordable, free, children }) => {
   // console.log("pricetag affordable?", affordable);
   return (
      <Text
         fontSize="sm"
         bg={free ? "gray.400" : affordable ? "green.400" : "red.400"}
         textDecoration={free ? "line-through" : ""}
         color="white" borderRadius={2} ml={2} p="2px 4px">
         <CValue>{ children }</CValue>
      </Text>
   )
};

const OpBox = ({ operation, msg, children }) => {
   
   const affordable = useAffordable(operation);

   const { name } = getOpNameAndFn(operation);
   const price = useS(`ops.${name}.price`);
   const free = useS(`ops.${name}.free`);

   return (
      <Box my={10}>
         { msg && <Box m="0 0 4px 2px"><Label>{msg.split(":")[0]}</Label>: {msg.split(":")[1]}</Box> }
         <Button disabled={!affordable} onClick={() => { op(operation)() }}>
            <Label>{ children }</Label>
            { price && <PriceTag {...{affordable, free}}>{ price }</PriceTag>}
         </Button>
      </Box>
   )
};

const BlowUpBridge = () => {

   const slice = "bridges";
   const increase = useS(`actions.playground.increase`);
   const count = useS(`playground.${slice}`);
   const blowUpBridge = () => {
      increase(slice);
   };

   const msg = `Bridges destroyed: ${count}`;

   return <OpBox operation={{blowUpBridge}} {...{msg}}>Blow up a bridge</OpBox>

};

const GrowOak = () => {

   const slice = "oaks";
   const increase = useS(`actions.playground.increase`);
   const count = useS(`playground.${slice}`);
   const growOak = () => {
      increase(slice);
   };
   const msg = `Oaks grown: ${count}`;

   return <OpBox operation={{growOak}} {...{msg}}>Grow an oak</OpBox>

};

const CallFriend = () => {

   const slice = "calls";
   const increase = useS(`actions.playground.increase`);
   const count = useS(`playground.${slice}`);
   const callFriend = () => {
      increase(slice);
   };
   const msg = `Friend calls: ${count}`;

   return <OpBox operation={{callFriend}} {...{msg}}>Call a friend</OpBox>

};

const BuildHouse = () => {

   const slice = "houses";
   const increase = useS(`actions.playground.increase`);
   const count = useS(`playground.${slice}`);
   const buildHouse = () => {
      increase(slice);
   };
   const msg = `Houses built: ${count}`;

   return <OpBox operation={{buildHouse}} {...{msg}}>Build a house</OpBox>

};

const PostComment = () => {

   const slice = "comments";
   const increase = useS(`actions.playground.increase`);
   const count = useS(`playground.${slice}`);
   const postComment = () => {
      increase(slice);
   };

   const msg = `Comments posted: ${count}`;

   return <OpBox operation={{postComment}} {...{msg}}>Post comment</OpBox>
};

export {
   BlowUpBridge, GrowOak, CallFriend, PostComment, BuildHouse,
}