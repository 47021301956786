const { Box } = require("@chakra-ui/react");

const Home = () => {

   return (
      <Box>
         home
      </Box>
   )
};

export default Home;
