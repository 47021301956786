import { getTable } from "api";
import { getJSON } from "api/json";
import produce from "immer";
import { merge } from "lodash";
import { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { convertStringsToValues, mapObject, objInObj, setDeepValue } from "shed";
import { useS, useStore } from "../store";

const mapRoutes = data => {
   return mapObject(data,
       obj => obj.hasOwnProperty("path"),
       obj => ({...obj, path: obj.path
           .split(/([\/:])/).filter(s=>s)
           .map(s=>/[\/:]/.test(s) ? s : encodeURIComponent(s))
           .join("") })
   )
};

const mapMergeArrayOfObjects = data => data.reduce((obj, next) => (
    merge(obj, next)
), {});

// const mapConfig = produce(data => { data.prefs = data.defaults; });

const required = [
   { json: "/data/json/routes.json", sliceKey: "routes", mapper: mapRoutes },
   { json: "/data/json/ops.json", sliceKey: "ops" },
   {
       json: ["/data/json/labels/ui.json", "/data/json/labels/playground.json"],
       sliceKey: "labels",
       mapper: mapMergeArrayOfObjects
    },
   { json: "/data/json/config.json" },
];

const mapper = (data, source) => source.hasOwnProperty("mapper") ? source.mapper(data) : data;

export const useRequiredData = () => {

   const queries = useQueries(required.map(source => ({
       queryKey: ["required", source.sliceKey],
       
       queryFn: source.json

       ? Array.isArray(source.json)
       ? () => {
           const promises = source.json.map(
               json => getJSON(json)
           );
           return Promise.all(promises).then(data => mapper(data, source))
       }
       : () => getJSON(source.json).then(data => mapper(data, source))

       : source.sql
       ? () => getTable(source.sql)
           .then(data =>
               convertStringsToValues(data)
               .map(obj => Object.entries(obj)
                   .reduce((acc, [key, value]) => (
                       value===null ? acc : {...acc, [key]: value }
                   ), {})
               )
           )
           .then(data => mapper(data, source))
       : async () => { return Promise.reject("no query function") }
   })));
   
   const fetched = queries.every(query => query.isSuccess);

   const { error } = queries.find(query => query.error) || {};

   const [data, set] = useState();

   const update = useStore(state => {
      // console.log("state:", state);
      return state.actions.update;
   });
   
   const loaded = useS("loaded");

   useEffect(() => {
       // console.log("loaded?", loaded);
       if (fetched && !loaded) {
            // console.log("fetched but nor updated");
            const state = required.reduce((state, slice, index) => {
               return merge(
                  state,
                  slice.sliceKey === undefined ? queries[index].data : setDeepValue(slice.sliceKey, queries[index].data)
               );
           }, { loaded: true });
           set(state);
       }
   }, [fetched, loaded, queries]);

   useEffect(() => {
       if (data) {
           // console.log(data.dict.morphs.filter(m => !!m.rule));
           update(data);
       }
   }, [data, update]);

   if (error) return { error, isError: true };

   return loaded
   ? { status: "success", isSuccess: true }
   : { status: "loading", isLoading: true }
};
