import { Box, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRequiredData } from "./data";
import { usePrefs } from "./prefs";

const Boot = ({ children }) => {
   const { isSuccess: dataLoaded, error } = useRequiredData();
   const prefsCreated = usePrefs();
   const isSuccess = dataLoaded && prefsCreated;

   useEffect(() => {
      console.time("data load");
   }, []);

   useEffect(() => {
      if (isSuccess) console.timeEnd("data load");
   }, [isSuccess, error]);

   return isSuccess ? (
      children
   ) : error ? (
      <Box>Error: {error}</Box>
   ) : (
      <Spinner />
   );
};

export default Boot;
