import { mode } from "@chakra-ui/theme-tools";

export const layout = {
   parts: ["layout", "panel"],
   baseStyle: props => ({
      layout: {
         display: "flex",
         flexGrow: 1,
      },
      panel: {},
   }),
   variants: {
      content: props => ({
         panel: { bg: mode("white", "black")(props), flex: "5 0" },
      }),
      sidebar: props => ({
         panel: {
            bg: mode("teal.50", "teal.900")(props),
            flex: "0 0 60px",
            w: "60px",
         },
      }),
   },
};
