import { cl, sameCase } from "shed";
import { useStore } from "store";

const Label = ({ children, fallback, ...key }) => {

   const label = useStore(state => {

      const category = cl(key) || "ui", labels = state.labels[category];

      const name = children, lang = state.prefs.lang;

      if (!labels || !labels[lang]) return fallback || children;

      const text = labels[lang][name.toLowerCase()];

      if (!text) return fallback || children;

      return sameCase(text, name);

   });

   return label

};

export default Label;
