import { isEqual, camelCase as lodashCamelCase } from "lodash";

export const dev = () => process.env.NODE_ENV === "development";

export function generateId() {
   function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
   }
   return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
   );
}

export function objType(obj) {
   return /^\[object (\w+)]$/
      .exec(Object.prototype.toString.call(obj))[1]
      .toLowerCase();
}

export const objInObj = (obj, parent) => {
   if (objType(obj) !== objType(parent)) return false;

   if (Array.isArray(obj))
      return obj.every(
         (item) =>
            parent.findIndex((parentItem) => isEqual(item, parentItem)) >= 0
      );

   if (objType(obj) !== "object") return isEqual(obj, parent);

   return Object.keys(obj).every(
      (key) => parent.hasOwnProperty(key) && isEqual(parent[key], obj[key])
   );
};

export const setDeepValue = (key, value) => {
   if (!/[^\.]+\.[^\.]+/.test(key)) return { [key]: value };

   const nested = key.split(".");

   const first = nested.shift();

   return { [first]: setDeepValue(nested.join("."), value) };
};

export function getDeepKeyValue(obj, keyString) {
   if (!/\./.test(keyString)) return obj[keyString];
   const keys = keyString.split(".");
   let res = obj;
   for (let i = 0; i < keys.length; i++) {
      res = res[keys[i]];
      if (res === undefined) break;
   }
   return res;
}

const str2val = (str) => {
   if (objType(str) !== "string") return str;
   if (str === "null") {
      // console.log(`str2val: string 'null' stays string`);
      return str;
   }
   try {
      const obj = JSON.parse(str);
      return obj;
   } catch {
      if (/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d\.\d{3}Z$/.test(str)) {
         // console.log("date found", new Date(str));
         return new Date(str);
      }
      return str;
   }
};

export const convertStringsToValues = (obj) => {
   if (Array.isArray(obj)) {
      return obj.map((o) => convertStringsToValues(o));
   }
   if (objType(obj) === "string") return str2val(obj);
   if (objType(obj) !== "object") return obj;
   return Object.entries(obj).reduce(
      (res, [key, val]) => ({ ...res, [key]: convertStringsToValues(val) }),
      {}
   );
};

export const values2mysql = (obj, separator) =>
   Object.values(obj)
      .map((value) =>
         typeof value === "string"
            ? `'${value}'`
            : value === null
            ? "NULL"
            : typeof value === "number"
            ? value
            : `'${JSON.stringify(value)}'`
      )
      .join(separator || ",");

export function getRandomInt(min, max) {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function aiove(val, inc) {
   return Array.isArray(val) ? val.indexOf(inc) !== -1 : val === inc;
}

export function ar(val) {
   return Array.isArray(val) ? val : [val];
}

export function aos(arrayOrString) {
   return Array.isArray(arrayOrString) ? arrayOrString.join("") : arrayOrString;
}

export function getValFromFuncOrVar(from) {
   if (objType(from) === "function")
      return from.apply(null, [].slice.call(arguments, 1));
   return from;
}

export function hasDeepProperty(sourceObj, propString) {
   if (/[^\.]+\.[^\.]+/.test(propString)) {
      const prop = propString.split(".");
      let obj = sourceObj;
      for (let i = 0; i < prop.length; i++) {
         //console.log("checking object:", obj);
         //console.log("property:", prop[i]);
         if (obj.hasOwnProperty(prop[i])) {
            //console.log("TRUE");
            obj = obj[prop[i]];
         } else {
            return false;
         }
      }
      return true;
   } else {
      //console.log("checking object:", sourceObj);
      //console.log("prop:", propString);
      //console.log(sourceObj.hasOwnProperty(propString));
      return sourceObj.hasOwnProperty(propString);
   }
}

export function cap(str) {
   return str.charAt(0).toUpperCase() + str.slice(1);
}

function whichCase(str) {
   if (str === str.toLowerCase()) return "lower";
   if (str === str.toUpperCase()) return "upper";
   if (str.charAt(0).toUpperCase() === str.charAt(0)) return "cap";
}

export function sameCase(str, origin) {
   switch (whichCase(origin)) {
      case "lower": return str.toLowerCase();
      case "upper": return str.toUpperCase();
      case "cap": return cap(str);
      default: return str;
   }
}

export function camelCase(str) {
   const attrs = {
       "class": "className",
       "rowspan": "rowSpan",
       "colspan": "colSpan",
       "srcset": "srcSet",
       "strokewidth": "strokeWidth"
   };
   if (attrs.hasOwnProperty(str)) return attrs[str];
   // https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case/37041217
   // return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
   return lodashCamelCase(str);
}

export const findDuplicates = (arr, key) => {
   const dups = [];
   arr.forEach((item) => {
      const d = arr.filter((e) =>
         key ? isEqual(e[key], item[key]) : isEqual(e, item)
      );
      if (d.length > 1) dups.push(item);
   });
   return dups;
};

export function entities2unicode(str) {
   if (objType(str) !== "string") {
      // console.log("entities2unicode: type mismatch:", str);
      // throw new Error(str);
      return str;
   }
   let res = str;
   [
      { e: "#9312", u: "\u2460" },
      { e: "#9313", u: "\u2461" },
      { e: "#9314", u: "\u2462" },
      { e: "#9315", u: "\u2463" },
      { e: "#9316", u: "\u2464" },
      { e: "#9317", u: "\u2465" },
      { e: "#9318", u: "\u2466" },
      { e: "#9319", u: "\u2467" },
      { e: "#9320", u: "\u2468" },

      { e: "times", u: "\u00D7" },
      { e: "thinsp", u: "\u2009" },
      { e: "copy", u: "\u00A9" },
      { e: "nbsp", u: "\u00A0" },
      { e: "mdash", u: "\u2014" },
      { e: "ndash", u: "\u2013" },
      { e: "shy", u: "\u00AD" },
      { e: "bdquo", u: "\u201E" },
      { e: "ldquo", u: "\u201C" },
      { e: "laquo", u: "\u00AB" },
      { e: "raquo", u: "\u00BB" },
   ].forEach((entity) => {
      res = res.replace(new RegExp(`&${entity.e};`, "g"), entity.u);
   });
   return res;
}

export function aovmap(obj, func) {
   if (Array.isArray(obj)) {
      return obj.map((elem, i) => func(elem, i));
   } else {
      return func(obj, 0);
   }
}

export function cl() {
   const classList = Array.prototype.slice
      .call(arguments)
      .filter((c) => c)
      .map((c) => {
         if (objType(c) === "object") {
            return Object.keys(c)
               // .map((key) => (c[key] === true ? key : false))
               .map((key) => (!!c[key] ? key : false))
               .filter((c) => c);
         }
         return c;
      })
      .flat(Infinity);

   // console.log(classList);

   return classList.length > 0 ? classList.filter((c) => c).join(" ") : null;
}

export const mapObject = (obj, condition, mutation) => {
   let res = obj;

   if (Array.isArray(obj)) {
      res = obj.map((el) => mapObject(el, condition, mutation));
   }

   if (objType(obj) === "object") {
      res = Object.entries(obj).reduce(
         (acc, [key, val]) => ({
            ...acc,
            [key]: mapObject(val, condition, mutation),
         }),
         {}
      );
   }

   return condition(res) ? mutation(res) : res;
};

export function romanize(num) {
   // https://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript
   if (isNaN(num)) return NaN;
   const digits = String(+num).split(""),
      key = [
         "",
         "C",
         "CC",
         "CCC",
         "CD",
         "D",
         "DC",
         "DCC",
         "DCCC",
         "CM",
         "",
         "X",
         "XX",
         "XXX",
         "XL",
         "L",
         "LX",
         "LXX",
         "LXXX",
         "XC",
         "",
         "I",
         "II",
         "III",
         "IV",
         "V",
         "VI",
         "VII",
         "VIII",
         "IX",
      ];
   let roman = "",
      i = 3;
   while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
   return Array(+digits.join("") + 1).join("M") + roman;
}

export const findChild = (obj, condition) => {
   if (Array.isArray(obj)) {
      for (let i = 0, l = obj.length; i < l; i++) {
         const res = findChild(obj[i], condition);
         if (res !== null) return res;
      }
      return null;
   }
   if (condition(obj)) return obj;
   if (objType(obj) === "object") {
      const keys = Object.keys(obj);
      for (let i = 0, l = keys.length; i < l; i++) {
         const res = findChild(obj[keys[i]], condition);
         if (res !== null) return res;
      }
   }
   return null;
};

export const findChildren = (obj, condition, parent, key) => {
   const children = [];
   if (Array.isArray(obj)) {
      for (let i = 0, l = obj.length; i < l; i++) {
         const res = findChildren(obj[i], condition, parent, key);
         if (res.length > 0) children.push(res);
      }
      return children.flat(Infinity);
   }
   if (condition(obj, parent, key)) children.push(obj);
   if (objType(obj) === "object") {
      const keys = Object.keys(obj);
      for (let i = 0, l = keys.length; i < l; i++) {
         const res = findChildren(obj[keys[i]], condition, obj, keys[i]);
         if (res.length > 0) children.push(res);
      }
   }
   return children.flat(Infinity);
};

export const findKey = (obj, condition) => {
   if (Array.isArray(obj)) {
      for (let i = 0, l = obj.length; i < l; i++) {
         const res = findKey(obj[i], condition);
         if (res !== null) return res;
      }
      return null;
   }
   if (objType(obj) === "object") {
      const keys = Object.keys(obj);
      for (let i = 0, l = keys.length; i < l; i++) {
         const key = keys[i];
         if (condition(obj[key])) return key;
         const res = findKey(obj[key], condition);
         if (res !== null) return res;
      }
   }
   return null;
};

export function includes(arr, obj, keys) {
   return keys?.length
      ? arr.some((item) => keys.every((key) => isEqual(obj[key], item[key])))
      : arr.some((item) => isEqual(obj, item));
}

export function getNRandoms(min, max, n) {
   const arr = [];
   while (arr.length < n) {
      const r = getRandomInt(min, max);
      if (arr.indexOf(r) < 0) arr.push(r);
   }
   return arr;
}

export const findBranch = (obj, cond, traverseKey, acc = []) => {
   if (Array.isArray(obj)) {
      // console.log("array");
      for (let i = 0, l = obj.length; i < l; i++) {
         const res = findBranch(obj[i], cond, traverseKey, acc);
         if (res) return res;
      }
      return false;
   }

   // console.log(obj);

   // console.log(`${key}: ${obj[key]}`);

   if (cond(obj)) {
      // console.log("finished");
      acc.push(obj);
      return acc;
   }

   if (obj.hasOwnProperty(traverseKey)) {
      const accObj = { ...obj };
      //    delete accObj[traverseKey];
      acc.push(accObj);

      const res = findBranch(obj[traverseKey], cond, traverseKey, acc);

      if (res) return acc;

      acc.pop();
   }

   // console.log("end of branch, not found");

   // console.log("acc:", acc);

   // acc.pop();

   // while (acc.length > 0) { acc.pop(); }

   return false;
};

export const identical = (arr) => {
   if (arr.length <= 1) return true;
   for (let i = 1, l = arr.length; i < l; i++) {
      if (!isEqual(arr[i], arr[i - 1])) return false;
   }
   return true;
};

export const punctuation = `\\\\«»„“.,:;!=+—–\\"\\'\\?\\s\\(\\)\\-\\/`;

export function removePunctuation(str) {
   const rx = new RegExp(`[${punctuation}]+`, "g");
   return str.replace(rx, " ").replace(/\s\s/g, " ").trim();
}

export const urlize = (str) => {
   const translit = {
      а: "a",
      б: "6",
      в: "8",
      г: "r",
      д: "d",
      е: "e",
      ё: "e",
      ж: "z",
      з: "3",
      и: "i",
      й: "j",
      к: "k",
      л: "l",
      м: "M",
      н: "H",
      о: "o",
      п: "n",
      р: "p",
      с: "c",
      т: "t",
      у: "y",
      ф: "f",
      х: "x",
      ц: "z",
      ч: "4",
      ш: "w",
      щ: "w",
      ъ: "",
      ы: "b1",
      ь: "b",
      э: "e",
      ю: "10",
      я: "R",
   };
   return removePunctuation(str)
      .replace(/\s/g, "-")
      .toLowerCase()
      .split("")
      .map((c) => translit[c] || c)
      .join("");
};

export const mapCollection = ({
   collection,
   acc = { href: "" },
   createAccValue = (obj) => urlize(obj.title),
   map,
}) => {
   // console.log("map collections");

   const parse = ({ obj, level = 0, acc, parent, createAccValue }) => {
      if (Array.isArray(obj)) {
         return obj.map((child) => {
            return parse({ obj: child, level, acc, parent, createAccValue });
         });
      }
      if (objType(obj) === "object") {
         const accValue = Object.keys(acc).reduce((newAccValue, key) => {
            // console.log(newAccValue);
            return {
               ...newAccValue,
               [key]: acc[key] + "/" + (obj[key] || createAccValue(obj, key)),
            };
         }, {});

         const child = { ...(map ? map(obj) : obj), ...accValue, level };

         if (parent) {
            child.parent = parent;
            if (!child.type && parent.type) {
               child.type = parent.type;
            }
         }
         if (obj.hasOwnProperty("children")) {
            if (child.type) {
               if (!/(^|\s)index$/.test(child.type)) child.type += " index";
            } else {
               child.type = "index";
            }
            const { children, ...parent } = child;
            child.children = parse({
               obj: children,
               level: level + 1,
               acc: accValue,
               parent,
               createAccValue,
            });
         } else {
            if (/(^|\s)index$/.test(child.type))
               child.type = child.type.replace(/(^|\s)index$/, "") || "item";
         }
         return child;
      }
   };

   const tree = parse({ obj: collection, acc, createAccValue });

   const arr = [];

   const tree2arr = (obj) => {
      if (Array.isArray(obj)) {
         obj.forEach((item) => {
            tree2arr(item);
         });
         return;
      }
      if (objType(obj) === "object") {
         arr.push(obj);
         if (obj.hasOwnProperty("children")) {
            tree2arr(obj.children);
         }
      }
   };

   tree2arr(tree);

   return arr;
};

export const xml2json = (xml, startNodeSelector, extractText) => {
   const allText = [];
   const mapNode = (node) => {
      const obj = {};

      if (node.nodeType === 3) {
         const text = node.nodeValue.trim();
         if (text.length > 0) {
            if (extractText) allText.push(text);
            return { name: "text", children: text };
         }
      }

      if (node.nodeType === 1) {
         obj.name = node.nodeName;
         if (node.hasAttributes()) {
            [...node.attributes].forEach((attrNode) => {
               obj[attrNode.nodeName] = attrNode.nodeValue;
            });
         }
         if (node.hasChildNodes()) {
            const childNodes = [...node.childNodes].filter(
               (node) => !(node.nodeType === 3 && node.nodeValue.trim() === "")
            );
            // console.log(children);
            if (childNodes.length > 0) {
               const children = childNodes.map((child) => mapNode(child));
               if (
                  children.length === 1 &&
                  children[0].name === "text" &&
                  children[0].children
               ) {
                  // single text node
                  obj.children = children[0].children;
               } else {
                  obj.children = children;
               }
            }
         }
      }

      return obj;
   };

   const node = xml.querySelector(startNodeSelector);

   // console.log(node);

   if (!node) return null;

   return extractText ? [mapNode(node), allText.join(" ").replace(/\s\s+/g, " ")] : mapNode(node);
};

export const json2jsx = (json, schema, origin) => {

   if (Array.isArray(json))
      return json.map((child, i) => json2jsx({ ...child, key: i }, schema, origin||json));

   if (objType(json) === "string") return json;

   let { children, name, ...props } = json;
   // console.log("mapChildren: component name:", cap(name));
   let Component;
   if (schema.hasOwnProperty(cap(name))) {
      Component = schema[cap(name)];
   } else {
      if (schema.hasOwnProperty(name)) {
         const [component, schemaProps] = schema[name];
         Component = component;
         props = {
            ...props,
            ...(objType(schemaProps) === "function"
            ? schemaProps(origin||json) : schemaProps)
         };
      }
   }

   if (Component) {
      props = { [json.name]: json, ...props };
      return children
      ? <Component {...props}>{json2jsx(children, schema, origin||json)}</Component>
      : <Component {...props} />;
   } else {
      return children
      ? json2jsx(children, schema, origin||json)
      : null;
   }
};

export function matchAll(string, regex, index = 1) {
   // https://stackoverflow.com/questions/432493/how-do-you-access-the-matched-groups-in-a-javascript-regular-expression
   // index || (index = 1);
   // default to the first capturing group
   const matches = [];
   let match,
      abort = 100;
   while ((match = regex.exec(string))) {
      matches.push(match[index]);
      abort--;
      if (abort === 0) return "error";
   }
   return matches;
}

export function match(string, regex) {
   const match = regex.exec(string);
   return match ? match[1] : null;
}

export function round(num, places=0) {
   // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
   const k = Math.pow(10, places);
   return num === 0 ? 0 : Math.round( ( num + Number.EPSILON ) * k ) / k;
}

export function formatNumber(n, lz, tz) {

   function fill(s, l, before) {
      if (l<=0) return s;
      let res = s;
      while (res.length < l) res = before ? "0" + res : res + "0";
      return res;
   }

   let [int, dec] = n.toString().split(".");

   int = fill(int||"", lz, true);
   dec = fill(dec||"", tz);

   return `${int}.${dec}`;

}