import Layout from "components/layout";
import Home from "features/home";
import Playground from "features/playground";
import { useMemo } from "react";
import { useRoutes } from "react-router-dom";
import { mapObject } from "shed";
import { useS } from "./store";

const elements = {
   Layout,
   Home, Playground,
};

const Router = () => {

   const routesMap = useS("routes");

   const routes = useMemo(() => mapObject(
       routesMap,
       obj => obj.hasOwnProperty("element"),
       route => {
           // console.log(route);
           const Element = elements[route.element];
           // console.log(Element);
           return {...route,
           element: <Element {...(route.props||{})} /> }
       }
   ), [routesMap]);

   // console.log("Router: elements:", routes);

   const staticRoutes = [
      //  { element: <Settings />, path: "/settings" }
   ];

   const element = useRoutes([...routes, ...staticRoutes]);

   return (
       element
   )
};

export default Router;
