import { Select } from "@chakra-ui/react";
import { useCallback } from "react";
import { useS } from "store";

const Lang = () => {

   const langs = useS("langs"),
   current = useS("prefs.lang"),
   update = useS("actions.prefs.update");

   const handleChange = useCallback(({ target }) => {
      // console.log(target.value);
      update({ lang: target.value });
   }, [update]);

   return (
      <Select w="unset" size="sm" value={current} onChange={handleChange}>
         { langs.map((lang, i) => (
            <option key={i}>{lang}</option>
         )) }
      </Select>
   )

};

export default Lang;
